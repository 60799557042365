<template>
	<div class="theme-dashboard-view">
		<h1>{{ $t("orders_search_title") }}</h1>
		<!-- Search Menu -->
		<div class="my-3 p-3 shadow-sm border">
			<div class="d-flex w-100">
				<span class="mx-2 p-2 rounded d-flex align-items-center col-2"
					:class="{ border: ShowFilters, 'bg-light': ShowFilters }" @click="
						() => {
							ShowFilters = !ShowFilters;
						}
					" style="cursor: pointer">
					<i class="fa fa-filter" aria-hidden="true"></i>
					<span class="mx-2" style="width: 15vh">{{
						$t("inventory_search_bar_filter_button")
					}}</span>
				</span>
				<div class="col-10 d-flex align-items-center px-2">
					<div class="col-md-8 col-sm-12 border d-flex">
						<input type="tel" :placeholder="$t('order_phone_number_search_placeholder')" class="p-2 w-100"
							:class="{
								'incorrect-input': validationError.length > 0,
							}" v-model="queryText" @keydown.enter="clickSearchOrder" />
						<button class="btn border theme-background-primary text-light p-2" @click="clickSearchOrder">
							{{ $t("inventory_search_bar_button_name") }}
						</button>
					</div>
				</div>
			</div>
			<!-- Filters -->
			<div class="my-2 d-flex" :class="{ 'd-flex': ShowFilters, 'd-none': !ShowFilters }">
				<!-- Status -->
				<div class="col-4 p-3">
					<strong>{{ $t("orders_search_filter_status") }}</strong>
					<div>
						<select class="p-2 my-2" style="width: 100%; height: 100%; border: 1px solid lightgrey"
							v-model="status">
							<option selected :value="null">
								{{ $t("orders_search_filter_status_default") }}
							</option>
							<option :value="0">{{ $t("orders_search_filter_status_pending") }}</option>
							<option :value="1">{{ $t("orders_search_filter_status_delivering") }}</option>
							<option :value="2">{{ $t("orders_search_filter_status_delivered") }}</option>
						</select>
					</div>
				</div>
				<!-- Payments Status -->
				<div class="col-4 p-3">
					<strong>{{ $t("orders_search_filter_payment_status") }}</strong>
					<div>
						<select class="p-2 my-2" style="width: 100%; height: 100%; border: 1px solid lightgrey"
							v-model="paymentStatus">
							<option :value="null">{{ $t("orders_search_filter_payment_status_default") }}</option>
							<option :value="true">{{ $t("orders_search_filter_payment_status_paid") }}</option>
							<option :value="false">{{ $t("orders_search_filter_payment_status_not_paid") }}</option>
						</select>
					</div>
				</div>
				<!-- Branch -->
				<div class="col-4 p-3">
					<strong>{{ $t("orders_search_filter_branch") }}</strong>
					<div>
						<select class="p-2 my-2" style="width: 100%; height: 100%; border: 1px solid lightgrey"
							v-model="branch">
							<option selected :value="null">{{ $t("orders_search_filter_branch_default") }}
							</option>
							<option :value="0">{{ $t("orders_search_filter_branch_amoudi") }} </option>
							<option :value="1">{{ $t("orders_search_filter_branch_nani") }}</option>
						</select>
					</div>
				</div>
			</div>
		</div>
		<!-- Loading Indicator -->
		<div class="d-flex justify-content-center">
			<LoadingIndicator :active="loading" />
		</div>
		<!-- Loading Error -->
		<div v-show="load_error">
			<div class="justify-content-center text-center text-danger my-4">
				<h4>{{ load_error }}</h4>
			</div>
		</div>

		<!-- Validation Error -->
		<div v-show="validationError">
			<div class="justify-content-center text-center text-danger my-4">
				<h4>{{ validationError }}</h4>
			</div>
		</div>

		<!-- Results -->
		<div class="m-4" v-show="!loading && results !== 0">
			<!-- <h3 class="">Results</h3> -->
			<!-- Results table -->
			<Table :rows="results ?? []" :hover="true" :contain="[
				'id',
				'user_phoneNumber',
				'type',
				'created_at',
				'status',
				'total_price',
			]" :rowClickFunction="clickOrder">
				<!-- Table Field Localization  -->
				<template v-slot:id-title>{{ $t("order_home_table_id") }}</template>
				<template v-slot:status-title>{{
					$t("order_home_table_status")
				}}</template>
				<template v-slot:type-title>{{
					$t("order_home_table_type")
				}}</template>
				<template v-slot:total_price-title>{{
					$t("order_home_table_total_price")
				}}</template>
				<template v-slot:user_phoneNumber-title>{{
					$t("order_home_table_phone_number")
				}}</template>
				<template v-slot:created_at-title>{{
					$t("order_home_table_created_at")
				}}</template>

				<!-- Created At Date for -->
				<template v-slot:created_at="params">
					<span>{{
						new Date(params.row.created_at).toLocaleDateString("en-UK", {
							weekday: 'short',
							day: "numeric",
							month: "numeric",
							year: "numeric",
							hour: "numeric",
							minute: "numeric",
							second: "numeric",
							hour12: true
						})
					}}</span>
				</template>

				<!-- Type Formatting -->
				<template v-slot:type="params">
					<div>
						<span v-show="params.row.type == 0">{{
							$t("single_order_type_on_site")
						}}</span>
						<span v-show="params.row.type == 1">{{
							$t("single_order_type_delivery")
						}}</span>
					</div>
				</template>


				<!-- Status Formatting -->
				<template v-slot:status="params">
					<div>
						<span style="border-radius: 5px" class="p-1 bg-warning text-light"
							v-show="params.row.status === 0">
							{{ $t("order_home_table_status_pending") }}
						</span>

						<span style="border-radius: 5px" class="p-1 bg-primary text-light"
							v-show="params.row.status === 1">
							{{ $t("order_home_table_status_delivering") }}
						</span>

						<span style="border-radius: 5px" class="p-1 bg-success text-light"
							v-show="params.row.status === 2">
							{{ $t("order_home_table_status_delivered") }}
						</span>
					</div>
				</template>
			</Table>
			<!-- No results -->
			<div v-show="
				!loading &&
				response.orders?.length == 0 &&
				queryText.length !== 0
			" class="text-center h4 text-danger">
				Order not found
			</div>
			<!-- Page Navigation -->
			<div v-show="results != 0">
				<div class="d-flex justify-content-center">
					<PageNav :pages="pages" :current_page="current_page" @click-page="clickOrderPage"
						@next-page="nextPage" @prev-page="prevPage" @goto-last="gotoLast" @goto-first="gotoFirst" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// Components
import Table from "@/components/Table.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PageNav from "@/components/PageNav.vue";

// Services
import SearchService from "@/services/search.js";

export default {
	name: "OrderSearch",
	components: {
		Table,
		LoadingIndicator,
		PageNav,
	},
	data() {
		return {
			// Display
			loading: false,
			load_error: "",
			ShowFilters: false,
			validationError: "",

			// Search parameters
			queryText: "",
			status: null,
			paymentStatus: null,
			branch: null,

			// Table parameters
			response: {},
			results: [],
			current_page: 1,
			pages: 0,
		};
	},
	methods: {
		// Handles the click event for search menu
		clickSearchOrder() {

			if (!this.validatePhoneNumber()) {
				this.validationError = "Invalid Phone Number";
				return;
			} else {
				this.validationError = "";
			}
			this.getSearchOrderResults(1);
		},

		validatePhoneNumber() {
			this.queryText.trim();

			// Check if it is begins in +2--
			const countryCodeCheck = new RegExp(
				"^[+][2]?[(]?[0-9]{2}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$"
			);
			if (countryCodeCheck.test(this.queryText)) {
				console.log("Country");
				return true;
			}

			// Check if it begins in 09
			const localCodeCheck = new RegExp(
				"^[09][-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$"
			);

			if (localCodeCheck.test(this.queryText)) {
				console.log("Local");
				const newNumber = "+251".concat(this.queryText.slice(1));
				console.log("new number:", newNumber);
				this.queryText = newNumber;
				return true;
			}

			return false;
		},
		parseBool(value) {
			if (typeof value === "boolean") {
				return value;
			}
			if (typeof value === "string") {
				if (value === "true") {
					return true;
				} else if (value === "false") {
					return false;
				} else {
					return null;
				}
			}
		},

		// Send request for order results
		getSearchOrderResults(page) {
			this.loading = true;
			this.load_error = "";
			this.results = [];

			const query_request = {
				phoneNumber: this.queryText,
				status: parseInt(this.status),
				payment_status: this.parseBool(this.paymentStatus),
				branch: parseInt(this.branch),
			};
			SearchService.SearchOrder(page, query_request).then(
				(response) => {
					this.loading = false;
					console.log(response);
					this.response = response.data.data;
					this.results = response.data.data.orders;
					this.pages = response.data.data.total_pages;
					this.current_page = page;
				},
				(error) => {
					this.loading = false;
					console.log("Error:", error.response);
					this.load_error =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
				}
			);
		},

		// Handles the click event for table result
		clickOrder(index) {
			this.$router.push("/orders/" + this.results[index].id);
		},

		// Page Navigation
		clickOrderPage(page) {
			this.getSearchOrderResults(page);
		},
		nextPage() {
			if (this.current_page !== this.pages) {
				this.getSearchOrderResults(this.current_page + 1);
			}
		},
		prevPage() {
			if (this.current_page !== 1) {
				this.getSearchOrderResults(this.current_page - 1);
			}
		},
		gotoLast() {
			if (this.current_page !== this.pages) {
				this.getSearchOrderResults(this.pages);
			}
		},
		gotoFirst() {
			if (this.current_page !== 1) {
				this.getSearchOrderResults(1);
			}
		},
	},

	mounted() {
		// Check if query text is passed from different page
		if (this.$route.params.query_text) {
			console.log('query_test', this.$route.params.query_text)
			// Fill in the passed parameters from the route
			this.queryText = this.$route.params.query_text;
			this.status = this.$route.params.query_status;
			this.paymentStatus = this.$route.params.query_payment_status;
			this.branch = this.$route.params.query_branch;

			this.getSearchOrderResults(1);
		}
	},
};
</script>

<style scoped>
.incorrect-input {
	border: 2px rgb(234, 31, 37) solid;
	background-color: lightcoral;
}
</style>
